import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How different sites answer the question:`}</h1>
    <blockquote>
      <p parentName="blockquote">{`How do you check if two numbers are equal in Javascript?`}</p>
    </blockquote>
    <p>{`Based loosely on `}<em parentName="p">{`actual answers`}</em>{` I have found...`}</p>
    <hr></hr>
    <h2>{`Stackoverflow`}</h2>
    <p>{`Oh easy, make sure you include jQuery first`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` value1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    value2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` value2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`undefined`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`is`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// W00t?`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Comments`}</h3>
    <p>{`Downvoted, you should never use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`undefined`}</code>{`! Direct comparisons against `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`undefined`}</code>{` are troublesome as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`undefined`}</code>{` can be overwritten, completely breaking your code! You can't risk this, nor can you trust anyone!!`}</p>
    <hr></hr>
    <h2>{`Yahoo answers`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`value1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`value2`}</code></pre></div>
    <p>{`Source: my brother who does programming`}</p>
    <hr></hr>
    <h2>{`Quora`}</h2>
    <p>{`Answered by: Travis Halvorson, PhD in Computer Science`}</p>
    <p>{`To find out whether or not two numbers are equal we must first determine how these numbers are stored...`}</p>
    <p>{`I checked Stack Overflow to see if they had a reasonable answer to this question, and unsurprisingly they didn't have anything good.`}</p>
    <p>{`I can't stand Stack Overflow. As someone who has a PhD in Software Engineering, I can't tell you the number of times I've banged my head against the wall, asking questions there from time to time. Like how do I get proper exchange of data from a Java server to a C# client's readbytes. It's a harder question than it sounds, but you get some 15 year old kid closing your question as a duplicate.`}</p>
    <p>{`To me, most people have problems reading at a college level, so to discourage someone from programming because the moderator wants to feel superior, is deplorable.`}</p>
    <p>{`Besides, learning is messy business. It looks messy, it feels messy, and it can be painful to look at. SO directly hurts education and severely limits knowledge, due to their need for drawing within the lines. More laws and rules are not what people need.`}</p>
    <p>{`You could argue that it's a private site and it has a right to act that way, but when you spam Google search results, with clean but mostly unhelpful code it kills efficiency in finding the right answer or direction.`}</p>
    <p>{`Anyway, here is a working answer`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` value1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` value2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isNaN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isNaN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`value2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` value1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` value2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Did I also mention I have a PhD??`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <hr></hr>
    <h2>{`Formspring`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Anonymous asked: How do you find if two numbers are equal?`}</p>
    </blockquote>
    <p>{`What? Who are you?`}</p>
    <hr></hr>
    <h2>{`Experts Exchange`}</h2>
    <h3>{`BEST SOLUTION`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Please sign up to read the actually useful answer, schmuck

               |SELL SOUL|       |NO THANKS|`}</code></pre></div>
    <h3>{`THE OTHER SOLUTIONS`}</h3>
    <p>{`Oh easy, make sure you include jQuery first...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      